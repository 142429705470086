import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from '@mui/material';
// import LanguageMenu from './LanguageMenu';
import UserMenu from './UserMenu';
// import Logo from '../../static/assets/logo-black.png';
import './NavigationBar.scss';

const Navigationbar = () => {
	const navigate = useNavigate();

	return (
		<div className="Navigation">
			<div className="Navigation__logo">
				<Link to="/">
					{/* eslint-disable-next-line global-require */}
					<img src={require('../../static/assets/logo-black.png')} alt="" />
				</Link>
			</div>
			<div className="Navigation__main-menu">
				<Button onClick={() => navigate('/courses')}>Courses</Button>
				<Button onClick={() => navigate('/course-occupation')}>Occupations</Button>
				<Button onClick={() => navigate('/quizzes')}>Quizzes</Button>
				<Button onClick={() => navigate('/code-tasks')}>Code Tasks</Button>
				<Button onClick={() => navigate('/video-items')}>Videos</Button>
				<Button onClick={() => navigate('/api-controls')}>APIs</Button>
				<Button onClick={() => navigate('/products')}>Products</Button>
				<Button onClick={() => navigate('/subscription-plans')}>Plans</Button>
				<Button onClick={() => navigate('/orders')}>Orders</Button>
				<Button onClick={() => navigate('/legals')}>Legals</Button>
				{/* <Button href="https://codeway.today" target="_blank">
                    {t("articles")}
                </Button> */}
				{/* <LanguageMenu /> */}
				<UserMenu />
			</div>
		</div>
	);
};

export default Navigationbar;
