import React, { FC, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@mui/material';
import { Section, VariableType } from './types';
import ApiControlSection from './ApiControlSection';

type ApiControlSectionsProps = {
	onUpdateSections: (sections: Section[]) => void;
	fieldSections: Section[];
	globalVariables: VariableType;
};

const ApiControlSections: FC<ApiControlSectionsProps> = ({
	fieldSections,
	onUpdateSections,
	globalVariables,
}) => {
	const [expanded, setExpanded] = useState<string[]>([]);

	const handleChange = (panel: string) => () => {
		if (expanded.includes(panel)) {
			setExpanded(expanded.filter((item) => item !== panel));
		} else {
			setExpanded([...expanded, panel]);
		}
	};

	const handleCreateNewSection = () => {
		const updated = [
			...fieldSections,
			{
				id: uuidv4(),
				title: 'New section',
				description: '',
				apiItems: [],
			},
		];
		onUpdateSections(updated);
	};

	const handleUpdateSection = (section: Section) => {
		const updated = fieldSections.map((item) => {
			if (item.id === section.id) {
				return section;
			}
			return item;
		});
		// setSections(updated);
		onUpdateSections(updated);
	};

	const handleRemoveSection = (section: Section) => {
		const updated = fieldSections.filter((item) => item.id !== section.id);
		// setSections(updated);
		onUpdateSections(updated);
	};

	console.log('fieldSections: ', fieldSections);

	return (
		<div>
			{fieldSections?.map((section) => (
				<Accordion
					expanded={expanded.includes(section.id)}
					onChange={handleChange(section.id)}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography
							variant="h6"
							sx={{ width: '33%', flexShrink: 0, fontSize: '1.125rem' }}
						>
							{section.title}
						</Typography>
						<Typography sx={{ color: 'text.secondary' }}>
							{section.apiItems.length} apis
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ApiControlSection
							onUpdateSection={handleUpdateSection}
							onRemoveSection={handleRemoveSection}
							section={section}
							globalVariables={globalVariables}
						/>
					</AccordionDetails>
				</Accordion>
			))}

			<div>
				<Button onClick={handleCreateNewSection}>+ Add new section</Button>
			</div>
		</div>
	);
};

export default ApiControlSections;
