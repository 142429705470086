import { TPaginationSettings } from '../../lib/interfaces/Pagination';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import { IApiControl } from '../../lib/interfaces/ApiControl';
import { API_METHODS, BASE_URL, createHeader } from '../endpointBuilder';
import { createRefsPaginatedUrl } from '../queryParamsBuilder';
import { axiosRequest } from '../request';

const endpoints = {
	getApiControlById: (id: number) => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/api-control/${id}`,
		method: API_METHODS.GET,
	}),

	getAllApiControls: () => ({
		headers: createHeader(null, true),
		url: `${BASE_URL}/api-control`,
		method: API_METHODS.GET,
	}),

	getPaginatedApiControls: (
		filter?: RefsFilter,
		pagination?: TPaginationSettings,
	) => {
		const url = createRefsPaginatedUrl(
			`${BASE_URL}/api-control`,
			pagination,
			filter,
		);
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.GET,
		};
	},

	createApiControl: (data: IApiControl) => ({
		method: API_METHODS.POST,
		url: `${BASE_URL}/api-control`,
		headers: createHeader(null, true),
		data,
	}),

	updateApiControl: (data: Partial<IApiControl>, id: number | undefined) => ({
		method: API_METHODS.PATCH,
		url: `${BASE_URL}/api-control/${id}`,
		headers: createHeader(null, true),
		data,
	}),

	deleteApiControl: (id: number | undefined) => ({
		method: API_METHODS.DELETE,
		url: `${BASE_URL}/api-control/${id}`,
		headers: createHeader(null, true),
	}),
};

export const getApiControlById = async (id: number) =>
	axiosRequest(endpoints.getApiControlById(id));

export const getAllApiControls = async () =>
	axiosRequest(endpoints.getAllApiControls());

export const getPaginatedApiControls = async (
	filter?: RefsFilter,
	pagination?: TPaginationSettings,
) => axiosRequest(endpoints.getPaginatedApiControls(filter, pagination));

export const createApiControl = async (data: IApiControl) =>
	axiosRequest(endpoints.createApiControl(data));

export const updateApiControl = async (
	data: Partial<IApiControl>,
	id: number | undefined,
) => axiosRequest(endpoints.updateApiControl(data, id));

export const deleteApiControl = async (id: number | undefined) =>
	axiosRequest(endpoints.deleteApiControl(id));
