import { FormField } from '../../lib/interfaces/Form';
import { FIELD_TYPES, createInitialContent } from '../Form/formUtils';

export const apiControlFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'tags',
		init: [],
		label: 'Tags',
		required: false,
		type: FIELD_TYPES.MULTISELECT,
		freeSolo: true,
	},
];

const contentExtraFields = [
	{
		name: 'apis',
		content: '',
		label: 'APIs',
		type: FIELD_TYPES.API_CONTROL_BLOCK,
	},
];

export const createApiControlInitialContent = () =>
	createInitialContent(contentExtraFields);
