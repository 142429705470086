import {
	FormField,
	IEntityContent,
	IEntityContentField,
} from '../../lib/interfaces/Form';
import { QuizTypes } from '../../lib/interfaces/Quiz';

export enum RefType {
	VIDEO_REF = 'videoRef',
	CODE_TASK_REF = 'codeTaskRef',
	QUIZ_REF = 'quizRef',
	COURSE_REF = 'courseRef',
	API_CONTROL_REF = 'apiControlRef',
}

// eslint-disable-next-line import/prefer-default-export
export const FIELD_TYPES = {
	TEXT: 'text',
	MULTISELECT: 'multiselect',
	SELECT: 'select',
	RICH_EDITOR: 'richEditor',
	FILE: 'file',
	CHECKBOX: 'checkbox',
	DIVIDER: 'divider',
	CODE_EDITOR: 'codeEditor',
	CODE_TASK_REF: RefType.CODE_TASK_REF,
	HINT: 'hint',
	QUIZ: RefType.QUIZ_REF,
	QUIZ_EDITOR: 'quizEditor',
	OVERVIEW_BLOCK: 'overviewBlock',
	CONCLUSION_BLOCK: 'conclusionBlock',
	VIDEO: 'video',
	VIDEO_REF: RefType.VIDEO_REF,
	COURSE_PICKER: 'coursePicker',
	CODESANDBOX_EMBED: 'codesandbox_embed',
	CODE_BLOCK: 'codeBlock',
	SAND_PACK: 'sandPack',
	SAND_PACK_WITH_TEXT: 'sandPackWithText',
	READ_ONLY: 'readOnly',
	API_CONTROL_BLOCK: 'apiControlBlock',
	API_CONTROL_REF: RefType.API_CONTROL_REF,
};

export const OPTION_TYPES = {
	CATEGORIES: 'category',
	TAGS: 'tags',
	OCCUPATIONS: 'occupation',
};

export const INPUT_TYPES = {
	EMAIL: 'email',
	PASSWORD: 'password',
	TEXT: 'text',
	NUMBER: 'number',
};

export const SUBMIT_BTN_TYPE = {
	FULL: 'full',
	INHERIT: 'inherit',
};

export const CUSTOM_FIELD_MENU_OPTION = {
	DELETE: 'delete',
	MOVE_UP: 'move_up',
	MOVE_DOWN: 'move_down',
	ADD_BEFORE: 'add_before',
	ADD_AFTER: 'add_after',
};

export enum LANGUAGES {
	EN = 'en',
	RU = 'ru',
}

export const defaultLaguage = LANGUAGES.EN;

export const supportedLaguages: string[] = [LANGUAGES.EN, LANGUAGES.RU];

export const getDefaultLanguageFieldName = (field: FormField) => {
	const [name] = field.name.split('_');
	return `${name}_${defaultLaguage}`;
};

export const createInitialContent = (
	extraFields: IEntityContentField[] = [],
): IEntityContent => {
	const fields: IEntityContent = {};

	supportedLaguages.forEach((lang) => {
		fields[lang] = [
			{
				type: FIELD_TYPES.TEXT,
				label: 'Name',
				name: `name_${lang}`,
				content: '',
			},
			{
				type: FIELD_TYPES.RICH_EDITOR,
				label: 'Content',
				name: `content_${lang}`,
				content: '',
			},
			...extraFields.map((field) => ({
				...field,
				name: `${field.name}_${lang}`,
			})),
		];
	});
	return fields;
};

// TODO: replace with createInitialContent
export const initialContent: IEntityContent = {
	en: [
		{
			type: 'text',
			label: 'Name',
			name: 'name_en',
			content: '',
		},
		{
			type: 'richEditor',
			label: 'Content',
			name: 'content_en',
			content: '',
		},
	],
	ru: [
		{
			type: 'text',
			label: 'Name',
			name: 'name_ru',
			content: '',
		},
		{
			type: 'richEditor',
			label: 'Content',
			name: 'content_ru',
			content: '',
		},
	],
};

// TODO: replace with createInitialContent
export const initialContentForProduct: IEntityContent = {
	en: [
		...initialContent.en,
		{
			type: 'richEditor',
			label: 'Full content',
			name: 'content_full_en',
			content: '',
		},
		{
			type: 'richEditor',
			label: 'Order page text',
			name: 'content_order_page_en',
			content: '',
		},
	],
	ru: [
		...initialContent.ru,
		{
			type: 'richEditor',
			label: 'Full content',
			name: 'content_full_ru',
			content: '',
		},
		{
			type: 'richEditor',
			label: 'Order page text',
			name: 'content_order_page_ru',
			content: '',
		},
	],
};

// QUIZ

export const quizInitialContentField = {
	type: 'quizEditor',
	label: 'Questions',
	name: 'questions',
	content: '',
};

const QUIZ_TYPES = [
	{
		label: 'Exam',
		value: QuizTypes.EXAM,
	},
	{
		label: 'Quiz',
		value: QuizTypes.QUIZ,
	},
];

export const quizFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'type',
		init: [],
		label: 'Type',
		required: true,
		type: FIELD_TYPES.SELECT,
		options: QUIZ_TYPES,
	},
];

// CODE TASK

const CODE_TASK_TYPES = [
	{
		label: 'Task',
		value: 1,
	},
];

export const codeTaskFields: FormField[] = [
	{
		name: 'name',
		init: '',
		label: 'Name',
		required: true,
		type: FIELD_TYPES.TEXT,
	},
	{
		name: 'type',
		init: 1,
		label: 'Type',
		required: true,
		type: FIELD_TYPES.SELECT,
		options: CODE_TASK_TYPES,
	},
];

export const codeTaskInitialContentFields: IEntityContentField[] = [
	{
		name: 'code',
		label: 'Initial Code',
		type: FIELD_TYPES.CODE_EDITOR,
		content: '',
		customType: 'codeTask',
	},
	{
		name: 'solution',
		label: 'Solution',
		type: FIELD_TYPES.CODE_EDITOR,
		content: '',
		customType: 'codeTask',
	},
];
