import React, { FC } from 'react';
import {
	Button,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import { ApiItem, ApiParam, Example, HttpMethods } from './types';
import InputTextField from '../../Form/InputTextField';
import { INPUT_TYPES } from '../../Form/formUtils';
import CKEditorField from '../CKEditor';
import EditableTable, { EditableTableColumn } from '../MultieTableEdit';
import ApiControlItemExamples from './ApiControlItemExamples';

const paramColumns: EditableTableColumn<ApiParam>[] = [
	// { field: 'id', headerName: 'Id', notEditable: true },
	{ field: 'name', headerName: 'Name' },
	{ field: 'description', headerName: 'Description' },
	{ field: 'type', headerName: 'Type' },
	{ field: 'required', headerName: 'Required', type: 'boolean' },
	{ field: 'example', headerName: 'Example' },
];

type ApiControlItemProps = {
	onUpdateApiItem: (apiItem: ApiItem) => void;
	onRemoveApiItem: (apiItem: ApiItem) => void;
	apiItem: ApiItem;
};

const ApiControlItem: FC<ApiControlItemProps> = ({
	onUpdateApiItem,
	onRemoveApiItem,
	apiItem,
}) => {
	// ApiItem content:
	const handleChangeHeading = (e: React.ChangeEvent<HTMLInputElement>) => {
		// setTitle(e.target.value);
		onUpdateApiItem({
			...apiItem,
			title: e.target.value,
		});
	};

	const handleChangeEndpoint = (e: React.ChangeEvent<HTMLInputElement>) => {
		// setTitle(e.target.value);
		onUpdateApiItem({
			...apiItem,
			endpoint: e.target.value,
		});
	};

	const handleContentBeforeChange = (data: string) => {
		// setDescription(data);
		onUpdateApiItem({
			...apiItem,
			description: data,
		});
	};

	const handleRemoveApiItem = () => {
		// eslint-disable-next-line no-restricted-globals
		const sure = confirm('Are you sure you want to remove this item?');
		if (sure) {
			onRemoveApiItem(apiItem);
		}
	};

	const handleMethodChange = (event: SelectChangeEvent) => {
		onUpdateApiItem({
			...apiItem,
			method: event.target.value as HttpMethods,
		});
	};

	const handleUpdateUrlParams = (data: ApiParam[], key: keyof ApiItem) => {
		onUpdateApiItem({
			...apiItem,
			[key]: data,
		});
	};

	const handleUpdateExamples = (examples: Example[]) => {
		onUpdateApiItem({
			...apiItem,
			examples,
		});
	};

	return (
		<div>
			<Button onClick={handleRemoveApiItem}>Remove item</Button>
			<InputTextField
				onChange={handleChangeHeading}
				variant="outlined"
				fullWidth
				size="small"
				label="Title"
				type={INPUT_TYPES.TEXT}
				defaultValue={apiItem.title}
			/>

			<CKEditorField
				// {...field}
				label="Description"
				defaultValue={apiItem.description}
				onChange={(data: string) => {
					handleContentBeforeChange(data);
				}}
			/>
			<div
				style={{
					display: 'flex',
					alignItems: 'flex-end',
					marginBottom: '10px',
					marginTop: '10px',
				}}
			>
				<div style={{ marginRight: '10px' }}>
					<Typography>Method</Typography>
					<Select
						labelId="template-selection"
						value={apiItem.method}
						onChange={handleMethodChange}
						size="small"
						variant="outlined"
					>
						{Object.entries(HttpMethods).map(([key, value]) => (
							<MenuItem value={value} key={value}>
								{key}
							</MenuItem>
						))}
					</Select>
				</div>
				<div style={{ width: '80%' }}>
					<InputTextField
						onChange={handleChangeEndpoint}
						variant="outlined"
						fullWidth
						size="small"
						label="Endpoint"
						type={INPUT_TYPES.TEXT}
						defaultValue={apiItem.endpoint}
					/>
				</div>
			</div>
			<div>
				<EditableTable
					title="URL Params"
					columns={paramColumns}
					data={apiItem.urlParams}
					onUpdate={(data: ApiParam[]) => handleUpdateUrlParams(data, 'urlParams')}
				/>
			</div>
			<div>
				<EditableTable
					title="Headers"
					columns={paramColumns}
					data={apiItem.headers}
					onUpdate={(data: ApiParam[]) => handleUpdateUrlParams(data, 'headers')}
				/>
			</div>
			<div>
				<EditableTable
					title="Body Params"
					columns={paramColumns}
					data={apiItem.bodyParams}
					onUpdate={(data: ApiParam[]) => handleUpdateUrlParams(data, 'bodyParams')}
				/>
			</div>
			<div>
				<ApiControlItemExamples
					examples={apiItem.examples}
					onUpdateExamples={handleUpdateExamples}
				/>
			</div>
		</div>
	);
};

export default ApiControlItem;
