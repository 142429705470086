import { Button, Tooltip } from '@mui/material';
import React, { FC, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VideocamIcon from '@mui/icons-material/Videocam';
import CodeIcon from '@mui/icons-material/Code';
import QuizIcon from '@mui/icons-material/Quiz';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import { PossibleRefs } from '../../../lib/interfaces/RefTypes';
import { defaultLaguage, RefType } from '../../Form/formUtils';
import RefSelector from './RefSelector';
import ApiControlRefAddition from '../ApiControl/ApiControlRefAddition';

type RefSelectorFieldProps = {
	field: FormField;
	formValues: any;
	// validation?: any;
	language: string;
	refType: RefType;
	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const RefSelectorField: FC<RefSelectorFieldProps> = ({
	field,
	formValues,
	language,
	handleNamedContentFieldChange,
	refType,
}) => {
	const [openSelector, setOpenSelector] = useState<boolean>(false);
	const handleChange = (item: PossibleRefs) => {
		handleNamedContentFieldChange(
			{
				value: item.id,
				options: formValues[field.name]?.options,
				reference: item,
			},
			field.name,
		);
		setOpenSelector(false);
	};

	const handleUpdateOptions = (data: any, optionKey: string) => {
		const options = formValues[field.name]?.options || {};
		const merged = {
			...options,
			[optionKey]: data,
		};
		handleNamedContentFieldChange(
			{
				...formValues[field.name],
				options: merged,
			},
			field.name,
		);
	};

	const fieldProps: {
		[key: string]: {
			name: string;
			link: string;
			linkField: 'id' | 'slug';
			icon: React.ReactElement;
		};
	} = {
		[RefType.VIDEO_REF]: {
			name: 'Video Reference',
			link: '/edit-video-item/',
			linkField: 'id',
			icon: <VideocamIcon />,
		},
		[RefType.CODE_TASK_REF]: {
			name: 'Code Task Reference',
			link: '/edit-code-task/',
			linkField: 'slug',
			icon: <CodeIcon />,
		},
		[RefType.QUIZ_REF]: {
			name: 'Quiz Reference',
			link: '/edit-code-task/',
			linkField: 'slug',
			icon: <QuizIcon />,
		},
		[RefType.API_CONTROL_REF]: {
			name: 'API Control Reference',
			link: '/edit-api-control/',
			linkField: 'id',
			icon: <QuizIcon />,
		},
	};

	const getDefaultLanguageFieldName = () => {
		const [name] = field.name.split('_');
		return `${name}_${defaultLaguage}`;
	};

	const defaultLanguageFieldName = getDefaultLanguageFieldName();

	console.log('FIELD: ', field);
	console.log(
		'formValues[defaultLanguageFieldName]: ',
		formValues[defaultLanguageFieldName],
	);

	return (
		<div>
			<div style={{ paddingBottom: '10px' }}>{fieldProps[refType].name}</div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{formValues[defaultLanguageFieldName]?.reference?.name && (
					<div
						style={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}
					>
						{fieldProps[refType].icon}{' '}
						{formValues[defaultLanguageFieldName]?.reference?.name}{' '}
						<a
							style={{ display: 'block', paddingLeft: '5px' }}
							href={`${fieldProps[refType].link}${
								formValues[defaultLanguageFieldName]?.reference?.[
									fieldProps[refType].linkField
								]
							}`}
							target="_blank"
							rel="noreferrer"
						>
							<OpenInNewIcon fontSize="small" />
						</a>
					</div>
				)}
				<Tooltip
					title={
						language !== defaultLaguage
							? 'Can be set or updated in default language only'
							: ''
					}
				>
					<span>
						<Button
							variant="outlined"
							onClick={() => {
								setOpenSelector(!openSelector);
							}}
							disabled={language !== defaultLaguage}
						>
							{formValues[defaultLanguageFieldName]?.reference?.name
								? 'Update'
								: 'Select'}
						</Button>
					</span>
				</Tooltip>

				{RefType.API_CONTROL_REF === refType &&
					formValues[defaultLanguageFieldName]?.reference && (
						<ApiControlRefAddition
							entity={formValues[defaultLanguageFieldName]?.reference}
							handleUpdateOptions={handleUpdateOptions}
							currentOptions={formValues[field.name]?.options}
						/>
					)}
			</div>
			{openSelector && (
				<RefSelector
					refType={refType}
					onSelect={handleChange}
					onCancel={() => {
						setOpenSelector(false);
					}}
				/>
			)}
		</div>
	);
};

export default RefSelectorField;
