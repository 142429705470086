import { Typography } from '@mui/material';
import UsersTable from './UsersTable';

const Home = () => {
	console.log('Hemo');
	return (
		<>
			<Typography variant="h4">Home</Typography>
			<UsersTable />
		</>
	);
};

export default Home;
