import { IApiControl } from '../../lib/interfaces/ApiControl';
import {
	deleteApiControl,
	getPaginatedApiControls,
} from '../../api/apiRequests/apiControls';
import EntityItemsPaginatedList from '../Smart/EntityItemsPaginatedList';

const ApiControls = () => {
	const renderListItem = (apiControl: IApiControl) => (
		<span>{apiControl.name}</span>
	);
	const getId = (apiControl: IApiControl) => apiControl.id;
	const getSlug = (apiControl: IApiControl) => apiControl.id.toString();

	return (
		<EntityItemsPaginatedList<IApiControl>
			entityName="API Controls"
			getPaginatedEntityFn={getPaginatedApiControls}
			deleteEntityFn={deleteApiControl}
			renderListItem={renderListItem}
			getId={getId}
			getSlug={getSlug}
			navigateEditPath="/edit-api-control/"
			navigateCreatePath="/create-api-control/"
		/>
	);
};

export default ApiControls;
