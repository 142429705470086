import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
// import cloneDeep from 'lodash/cloneDeep';
// import { initialContent } from '../Form/formUtils';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { IApiControl } from '../../lib/interfaces/ApiControl';
import { createApiControl } from '../../api/apiRequests/apiControls';
import {
	apiControlFields,
	createApiControlInitialContent,
} from './ApiControlsFormFields';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';

const ApiControlCreate = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createApiControlMutation = useMutation<IApiControl, Error, IApiControl>(
		(formValues) => createApiControl(formValues),
	);
	const { isLoading, isSuccess, error } = createApiControlMutation;

	useEffect(() => {
		if (isSuccess) {
			queryClient.invalidateQueries(['apiControls']);
			navigate(-1);
		}
	}, [isSuccess]);

	const onSubmit = (formValues: any) => {
		createApiControlMutation.mutate(formValues);
	};

	const initialApiControlContent = createApiControlInitialContent();
	const apiControlInit: Partial<IApiControl> = {
		content: initialApiControlContent,
	};

	return (
		<SingleContentEntity
			isEditMode={false}
			onSubmit={onSubmit}
			isLoading={isLoading}
			initialValues={apiControlInit}
			error={error}
			saveButtonName="Create"
			metaFields={apiControlFields}
			entityName="API Control"
			contentEntity={CONTENT_ENTITIES.API_CONTROL}
		/>
	);
};

export default ApiControlCreate;
