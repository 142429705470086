import React, { useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TextField,
	Button,
	IconButton,
	Checkbox,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

export type EditableTableColumn<T> = {
	field: keyof T;
	headerName: string;
	notEditable?: boolean;
	hidden?: boolean;
	type?: 'string' | 'boolean';
};

interface TableProps<T> {
	columns: EditableTableColumn<T>[];
	data: T[];
	title: string;
	onUpdate?: (updatedData: T[]) => void;
}

const EditableTable = <T extends { id: string }>({
	columns,
	data: initialData,
	title,
	onUpdate,
}: TableProps<T>) => {
	const [data, setData] = useState<T[]>(initialData);

	const handleCellChange = (id: T['id'], field: keyof T, value: any) => {
		// eslint-disable-next-line no-confusing-arrow
		const updatedData = data.map((row) =>
			row.id === id ? { ...row, [field]: value } : row,
		);
		setData(updatedData);
		onUpdate?.(updatedData);
	};

	const handleAddRow = () => {
		const newRow = {} as T;
		columns.forEach(({ field }) => {
			newRow[field] = '' as any;
		});
		newRow.id = uuidv4();
		const updatedData = [...data, newRow];
		setData(updatedData);
		onUpdate?.(updatedData);
	};

	const handleRemoveRow = (id: T['id']) => {
		const updatedData = data.filter((row) => row.id !== id);
		setData(updatedData);
		onUpdate?.(updatedData);
	};

	return (
		<>
			<Typography>
				{title}{' '}
				<Button
					startIcon={<AddIcon />}
					onClick={handleAddRow}
					variant="text"
					sx={{ margin: 2 }}
					size="small"
				>
					Add Row
				</Button>
			</Typography>
			{!!data.length && (
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell key={column.field as string}>{column.headerName}</TableCell>
								))}
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row) => (
								<TableRow key={row.id}>
									{columns.map((column) => {
										if (column.type === 'boolean') {
											return (
												<div style={{ margin: '13px 0 0 10px' }}>
													<Checkbox
														value={row[column.field] || false}
														onChange={(e) =>
															handleCellChange(row.id, column.field, e.target.checked)
														}
													/>
												</div>
											);
										}
										return (
											<TableCell key={column.field as string}>
												<TextField
													disabled={column.notEditable}
													value={row[column.field] || ''}
													onChange={(e) =>
														handleCellChange(row.id, column.field, e.target.value)
													}
													variant="outlined"
													size="small"
												/>
											</TableCell>
										);
									})}
									<TableCell>
										<IconButton onClick={() => handleRemoveRow(row.id)}>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</>
	);
};

export default EditableTable;
