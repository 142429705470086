const en = {
	signIn: 'Sign In',
	home: 'Courses',
	english: 'English',
	russian: 'Russian',
	lang_en: 'English',
	lang_ru: 'Russian',
	hey: 'Hey',
	logout: 'Logout',
	login: 'Login',
	email: 'Your Email',
	password: 'Password',
	register: 'Registration',
	name: 'Name',
	goToCourse: 'Go To Course',
	takeThisCourse: 'Take This Course',
	coursesInProgress: 'Courses in progress',
	availableCourses: 'Available courses',
	completedCourses: 'Completed courses',
	noProgressCourses: "You haven't took any course yet",
	noAvailableCourses:
		'Congratulations, you have finished all available courses!',
	noCompletedCourses: "You haven't accomplished any course yet",
	completeTask: 'Mark task as Completed',
	setTaskIncomplete: 'Mark Task as Incomplete',
	replyTo: 'Reply to',
	editComment: 'Edit comment',
	writeComment: 'Write a comment',
	linkLabel:
		'Link (only from: github.com, codepen.io, jsfiddle.net, codesandbox.io)',
	yourMessage: 'Your message',
	whitelistLinksSupport: 'We only support links only from',
	linkIsNotValid: 'Link is not valid',
	reply: 'Reply',
	send: 'Send',
	edit: 'Edit',
	cancel: 'Cancel',
	congrats: 'Congratulations!',
	completeCourseMessage: 'You have finished the course!',
	takeAnotherCourse: 'Take another course',
	hint: 'Hint',
	articles: 'Articles',
	practice: 'Practice',
	theory: 'Theory',
	all: 'All',
	yourProgress: 'Your progress',
	donate: 'Donate',
	field_text: 'Text',
	field_multiselect: 'Multi select',
	field_richEditor: 'Rich text editor',
	field_file: 'File',
	field_checkbox: 'Checkbox',
	field_divider: 'Divider',
	field_codeEditor: 'Code editor',
	field_quizRef: 'Quiz reference',
	field_quizEditor: 'Quiz Editor',
	field_hint: 'Hint / Solution',
	field_codeTaskRef: 'Code Task reference',
	field_overviewBlock: 'Overview Block',
	field_conclusionBlock: 'Summary Block',
	field_videoRef: 'Video reference',
	requiredField: 'Required field',
	field_codesandbox_embed: 'Codesandbox id',
	field_codeBlock: 'Code Block',
	field_sandPack: 'Sandpack',
	field_sandPackWithText: 'Sandpack with text',
	field_apiControlRef: 'API control reference',
};

export default en;
