import { User } from '../../lib/interfaces/User';
import {
	IPagination,
	TPaginationSettings,
} from '../../lib/interfaces/Pagination';
import { RefsFilter } from '../../lib/interfaces/RefTypes';
import { createHeader, BASE_URL, API_METHODS } from '../endpointBuilder';
import { createRefsPaginatedUrl } from '../queryParamsBuilder';
import { axiosRequest } from '../request';

type UsersOptions = {
	filter?: RefsFilter;
	pagination?: TPaginationSettings;
};

const endpoints = {
	getPaginatedUsers: ({ pagination }: UsersOptions) => {
		const url = createRefsPaginatedUrl(
			`${BASE_URL}/user-dashboard`,
			pagination,
			null,
		);
		console.log('url: ', url, pagination);
		return {
			headers: createHeader(null, true),
			url,
			method: API_METHODS.GET,
		};
	},
};

export const getPaginatedUsers = async ({
	filter,
	pagination,
}: UsersOptions): Promise<IPagination<User>> =>
	axiosRequest(endpoints.getPaginatedUsers({ filter, pagination }));
