import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { v4 as uuidv4 } from 'uuid';
import { INPUT_TYPES } from '../../Form/formUtils';
import InputTextField from '../../Form/InputTextField';
import CKEditorField from '../CKEditor';
import { Example } from './types';

// 1. Request
// 2. Response success
// 3. Response error

type ApiControlItemExamplesProps = {
	examples: Example[];
	onUpdateExamples: (data: Example[]) => void;
};

const ApiControlItemExamples: FC<ApiControlItemExamplesProps> = ({
	examples,
	onUpdateExamples,
}) => {
	const [currentTab, setCurrentTab] = React.useState('one');

	const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
		setCurrentTab(newValue);
	};

	const handleChangeTitle = (data: string, exampleItem: Example) => {
		onUpdateExamples(
			examples.map((item) => {
				if (item.id === exampleItem.id) {
					return {
						...exampleItem,
						title: data,
					};
				}
				return item;
			}),
		);
	};

	const handleChangeDescription = (data: string, exampleItem: Example) => {
		onUpdateExamples(
			examples.map((item) => {
				if (item.id === exampleItem.id) {
					return {
						...exampleItem,
						description: data,
					};
				}
				return item;
			}),
		);
	};

	const handleAddExample = () => {
		onUpdateExamples([
			...examples,
			{
				id: uuidv4(),
				title: 'New example',
				description: '',
			},
		]);
	};

	const handleRemoveExample = (exampleItem: Example) => {
		onUpdateExamples(examples.filter((item) => item.id !== exampleItem.id));
	};
	return (
		<div>
			<Typography>
				Examples{' '}
				<Button
					startIcon={<AddIcon />}
					onClick={handleAddExample}
					variant="text"
					sx={{ margin: 2 }}
					size="small"
				>
					Add Example
				</Button>
			</Typography>
			{!!examples.length && (
				<Box sx={{ width: '100%' }}>
					<Tabs value={currentTab} onChange={handleChangeTab}>
						{examples.map((exampleItem) => (
							<Tab
								key={exampleItem.id}
								value={exampleItem.id}
								label={exampleItem.title}
							/>
						))}
					</Tabs>
					{examples.map((exampleItem) => (
						<div key={exampleItem.id}>
							{exampleItem.id === currentTab ? (
								<div style={{ marginTop: '15px' }}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<InputTextField
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												handleChangeTitle(e.target.value, exampleItem)
											}
											variant="outlined"
											fullWidth
											size="small"
											label="Title"
											type={INPUT_TYPES.TEXT}
											defaultValue={exampleItem.title}
										/>
										<div style={{ width: '200px' }}>
											<Button
												startIcon={<DeleteIcon />}
												onClick={() => handleRemoveExample(exampleItem)}
												variant="text"
												sx={{ margin: 2 }}
												size="small"
											>
												Remove example
											</Button>
										</div>
									</div>

									<CKEditorField
										// {...field}
										label="Description"
										defaultValue={exampleItem.description}
										onChange={(data: string) => {
											handleChangeDescription(data, exampleItem);
										}}
									/>
								</div>
							) : null}
						</div>
					))}
				</Box>
			)}
		</div>
	);
};

export default ApiControlItemExamples;
