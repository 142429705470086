import React, { FC, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { v4 as uuidv4 } from 'uuid';
import {
	Button,
	Chip,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { ApiItem, ApiType, HttpMethods, Section, VariableType } from './types';
import InputTextField from '../../Form/InputTextField';
import { INPUT_TYPES } from '../../Form/formUtils';
import CKEditorField from '../CKEditor';
import ApiControlItem from './ApiControlItem';
import { formatString } from './utils';

const colorByMethodDict = {
	[HttpMethods.GET]: '#61affe',
	[HttpMethods.POST]: '#49cc90',
	[HttpMethods.PUT]: '#fba030',
	[HttpMethods.PATCH]: '#eae738',
	[HttpMethods.DELETE]: '#f93e3e',
	[HttpMethods.WS]: '#a56de1',
};

type ApiControlSectionProps = {
	onUpdateSection: (section: Section) => void;
	onRemoveSection: (section: Section) => void;
	section: Section;
	globalVariables: VariableType;
};

const ApiControlSection: FC<ApiControlSectionProps> = ({
	onUpdateSection,
	onRemoveSection,
	section,
	globalVariables,
}) => {
	const [expanded, setExpanded] = useState<string[]>([]);
	const [selectedApiType, setSelectedApiType] = useState<ApiType>(ApiType.REST);

	const handleChange = (panel: string) => () => {
		if (expanded.includes(panel)) {
			setExpanded(expanded.filter((item) => item !== panel));
		} else {
			setExpanded([...expanded, panel]);
		}
	};

	const handleCreateNewItem = () => {
		const defaultMethod = {
			[ApiType.REST]: HttpMethods.GET,
			[ApiType.WEBSOCKET]: HttpMethods.WS,
			[ApiType.GRAPHQL]: HttpMethods.POST,
		};
		onUpdateSection({
			...section,
			apiItems: [
				...section.apiItems,
				{
					id: uuidv4(),
					title: 'New api item',
					description: '',
					type: selectedApiType,
					method: defaultMethod[selectedApiType],
					endpoint: '',
					urlParams: [],
					bodyParams: [],
					headers: [],
					examples: [],
				},
			],
		});
	};

	// Section content:
	const handleChangeHeading = (e: React.ChangeEvent<HTMLInputElement>) => {
		// setTitle(e.target.value);
		onUpdateSection({
			...section,
			title: e.target.value,
		});
	};

	const handleContentBeforeChange = (data: string) => {
		// setDescription(data);
		onUpdateSection({
			...section,
			description: data,
		});
	};

	const handleRemoveSection = () => {
		// eslint-disable-next-line no-restricted-globals
		const sure = confirm('Are you sure you want to remove this section?');
		if (sure) {
			onRemoveSection(section);
		}
	};

	const handleUpdateApiItem = (apiItem: ApiItem) => {
		onUpdateSection({
			...section,
			apiItems: section.apiItems.map((item) => {
				if (item.id === apiItem.id) {
					return apiItem;
				}
				return item;
			}),
		});
	};

	const handleRemoveApiItem = (apiItem: ApiItem) => {
		onUpdateSection({
			...section,
			apiItems: section.apiItems.filter((item) => item.id !== apiItem.id),
		});
	};

	const getColorByMethod = (method: HttpMethods) => colorByMethodDict[method];

	console.log('globalVariables: ', globalVariables);

	return (
		<div>
			<Button onClick={handleRemoveSection}>Remove section</Button>
			<InputTextField
				onChange={handleChangeHeading}
				variant="outlined"
				fullWidth
				label="Title"
				// name={field.name}
				type={INPUT_TYPES.TEXT}
				defaultValue={section.title}
				// className={validation[field.name] ? 'invalid' : ''}
				// fieldInputType={field.inputType}
			/>

			<CKEditorField
				// {...field}
				label="Description"
				defaultValue={section.description}
				onChange={(data: string) => {
					handleContentBeforeChange(data);
				}}
			/>
			{section.apiItems?.map((item) => (
				<Accordion
					expanded={expanded.includes(item.id)}
					onChange={handleChange(item.id)}
					style={{ border: `3px solid ${getColorByMethod(item.method)}` }}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id={item.id}
					>
						<Typography
							sx={{
								width: '33%',
								flexShrink: 0,
								display: 'flex',
								columnGap: '10px',
								alignItems: 'center',
							}}
						>
							<Chip
								label={item.method?.toUpperCase()}
								style={{ background: getColorByMethod(item.method), color: '#fff' }}
							/>
							{item.title}
						</Typography>
						<Typography sx={{ color: 'text.secondary' }}>
							{formatString(item.endpoint, globalVariables)}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ApiControlItem
							onUpdateApiItem={handleUpdateApiItem}
							onRemoveApiItem={handleRemoveApiItem}
							apiItem={item}
						/>
					</AccordionDetails>
				</Accordion>
			))}
			<div>
				<div style={{ marginTop: '10px' }}>
					<Select
						value={selectedApiType}
						onChange={(event: SelectChangeEvent) =>
							setSelectedApiType(event.target.value as ApiType)
						}
						size="small"
						variant="outlined"
					>
						{Object.entries(ApiType).map(([key, value]) => (
							<MenuItem value={value} key={value}>
								{key}
							</MenuItem>
						))}
					</Select>
					<Button onClick={handleCreateNewItem}>+ Add new api</Button>
				</div>
			</div>
		</div>
	);
};

export default ApiControlSection;
