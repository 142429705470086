export type ApiParam = {
	id: string;
	name: string;
	type: string;
	required: boolean;
	description?: string;
	example?: string;
};

export type UrlParam = ApiParam;

export type BodyParam = ApiParam;

export type HeaderParam = ApiParam;

export type Example = {
	id: string;
	title: string;
	description: string;
};

export enum HttpMethods {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	PATCH = 'patch',
	DELETE = 'delete',
	WS = 'WebSocket',
}

export enum ApiType {
	REST = 'REST',
	WEBSOCKET = 'WebSocket',
	GRAPHQL = 'GraphQL',
}

export type ApiItem = {
	id: string; //
	title: string; //
	description: string; //
	endpoint: string; //
	method: HttpMethods; //
	urlParams: UrlParam[];
	bodyParams: BodyParam[];
	headers: HeaderParam[];
	examples: Example[];
	type: ApiType;
};

export type Section = {
	id: string;
	title: string;
	description: string;
	apiItems: ApiItem[];
};

export type VariableValueType = {
	variableKey: string;
	variableValue: string;
};

export type VariableType = {
	[key: string]: string;
};

export type ApiControlContent = {
	globalVariables: VariableType;
	sections: Section[];
};
