import { FC } from 'react';
import { Select, SelectChangeEvent, MenuItem, Typography } from '@mui/material';
import { IApiControl } from '../../../lib/interfaces/ApiControl';
import { defaultLaguage, FIELD_TYPES } from '../../Form/formUtils';
import { Section } from './types';

type ApiControlRefAdditionProps = {
	entity: IApiControl;
	handleUpdateOptions: (data: any, optionKey: string) => void;
	currentOptions: any;
};

enum SelectionLevel {
	FULL = 'full',
	SECTION = 'section',
	API = 'api',
}

type SelectionType = {
	id: string;
	name: string;
};

const ApiControlRefAddition: FC<ApiControlRefAdditionProps> = ({
	entity,
	handleUpdateOptions,
	currentOptions,
}) => {
	console.log('entity: ', entity);
	const API_CONTROL_SELECTIONS_KEY = 'apiControlSelections';
	const getAllSections = () => {
		const content = entity?.content?.[defaultLaguage];
		const apiControlField = content.find(
			(field) => field.type === FIELD_TYPES.API_CONTROL_BLOCK,
		);
		if (apiControlField) {
			const sections = apiControlField?.options?.sections;
			return sections.map((section: Section) => ({
				id: section.id,
				name: section.title,
			}));
		}
		return [];
	};
	const getAllApis = () => {
		const content = entity.content[defaultLaguage];
		const apiControlField = content.find(
			(field) => field.type === FIELD_TYPES.API_CONTROL_BLOCK,
		);
		if (apiControlField) {
			const sections = apiControlField?.options?.sections;
			if (Array.isArray(sections)) {
				const apis: SelectionType[] = [];
				console.log('sections: ', sections);
				sections.forEach((section: Section) => {
					if (Array.isArray(section.apiItems)) {
						console.log('section.apiItems: ', section.apiItems);
						section.apiItems.forEach((item) => {
							apis.push({
								id: item.id,
								name: `${section.title} > ${item.title}`,
							});
						});
					}
				});
				return apis;
			}
		}
		return [];
	};

	const handleUpdate = (value: string, key: string) => {
		if (key === 'level') {
			handleUpdateOptions(
				{
					level: value,
					id: undefined,
				},
				API_CONTROL_SELECTIONS_KEY,
			);
		} else {
			handleUpdateOptions(
				{
					level: currentOptions?.[API_CONTROL_SELECTIONS_KEY].level,
					id: value,
				},
				API_CONTROL_SELECTIONS_KEY,
			);
		}
	};

	return (
		<div>
			{!entity.content ? (
				<div style={{ marginLeft: '20px' }}>
					First, create a task. Adjustments will be available in edit mode only
				</div>
			) : (
				<div style={{ display: 'flex', gap: '20px', marginLeft: '20px' }}>
					<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
						<Typography>Level: </Typography>
						<Select
							value={currentOptions?.[API_CONTROL_SELECTIONS_KEY].level || undefined}
							onChange={(event: SelectChangeEvent) =>
								handleUpdate(event.target.value, 'level')
							}
							size="small"
							variant="outlined"
						>
							{Object.entries(SelectionLevel).map(([key, value]) => (
								<MenuItem value={value} key={value}>
									{key}
								</MenuItem>
							))}
						</Select>
					</div>
					{currentOptions?.[API_CONTROL_SELECTIONS_KEY].level ===
						SelectionLevel.SECTION && (
						<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
							<Typography>Section: </Typography>
							<Select
								value={currentOptions?.[API_CONTROL_SELECTIONS_KEY].id || undefined}
								onChange={(event: SelectChangeEvent) =>
									handleUpdate(event.target.value, 'section')
								}
								size="small"
								variant="outlined"
							>
								{getAllSections().map((section: SelectionType) => (
									<MenuItem value={section.id} key={section.id}>
										{section.name}
									</MenuItem>
								))}
							</Select>
						</div>
					)}
					{currentOptions?.[API_CONTROL_SELECTIONS_KEY].level ===
						SelectionLevel.API && (
						<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
							<Typography>API: </Typography>
							<Select
								value={currentOptions?.[API_CONTROL_SELECTIONS_KEY].id || undefined}
								onChange={(event: SelectChangeEvent) =>
									handleUpdate(event.target.value, 'api')
								}
								size="small"
								variant="outlined"
							>
								{getAllApis().map((api: SelectionType) => (
									<MenuItem value={api.id} key={api.id}>
										{api.name}
									</MenuItem>
								))}
							</Select>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ApiControlRefAddition;
