import React, { FC, useEffect, useState } from 'react';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { VariableType, VariableValueType } from './types';

type ApiControlVariablesProps = {
	onVariableChange: (vars: VariableType) => void;
	variables?: VariableType;
};

const ApiControlVariables: FC<ApiControlVariablesProps> = ({
	onVariableChange,
	variables,
}) => {
	const initialVars = variables || { '': '' };
	const [vars, setVars] = useState<VariableType>({ ...initialVars });
	const initialVariableState: VariableValueType = {
		variableKey: '',
		variableValue: '',
	};
	const [value, setValue] = useState<VariableValueType>({
		...initialVariableState,
	});

	const handleUpdate = (newVariableValue: VariableType) => {
		delete newVariableValue[''];
		onVariableChange(newVariableValue);
	};

	const onSave = () => {
		const newVariableValue = {
			...vars,
			[value.variableKey]: value.variableValue,
			'': '',
		};
		setVars(newVariableValue);
		handleUpdate({ ...newVariableValue });
	};

	const removeVar = (dep: string) => {
		const varsNewValue = { ...vars };
		delete varsNewValue[dep];
		setVars(varsNewValue);
		handleUpdate({ ...varsNewValue });
	};

	useEffect(() => {
		setValue({ ...initialVariableState });
	}, [vars]);

	return (
		<div style={{ padding: '15px 0' }}>
			<Typography variant="body1">Global variables:</Typography>
			{Object.entries(vars).map(([dep, variableValue]) => (
				<div key={dep}>
					{dep && variableValue ? (
						<>
							<span>{dep}</span> : <span>{variableValue}</span>
							<IconButton
								aria-label="delete"
								size="medium"
								onClick={() => removeVar(dep)}
							>
								<DeleteForeverIcon fontSize="inherit" />
							</IconButton>
						</>
					) : null}
				</div>
			))}

			<div>
				<TextField
					value={value.variableKey}
					size="small"
					placeholder="variableKey"
					onChange={(e) => setValue({ ...value, variableKey: e.target.value })}
				/>
				<TextField
					value={value.variableValue}
					size="small"
					placeholder="variableValue"
					onChange={(e) => setValue({ ...value, variableValue: e.target.value })}
				/>
				<Button variant="text" onClick={onSave}>
					Add
				</Button>
			</div>
		</div>
	);
};

export default ApiControlVariables;
