import { FC } from 'react';
import {
	FormField,
	IEntityContentFormValuesField,
} from '../../../lib/interfaces/Form';
import ApiControlVariables from './ApiControlVariables';
import ApiControlSections from './ApiControlSections';
import { Section, VariableType } from './types';

type ApiControlFieldProps = {
	field: FormField;
	formValues: any;
	language?: string;
	handleNamedContentFieldChange: (
		data: IEntityContentFormValuesField,
		name: string,
	) => void;
};

const ApiControlField: FC<ApiControlFieldProps> = ({
	field,
	formValues,
	handleNamedContentFieldChange,
	language,
}) => {
	console.log('ApiControlField', language);
	console.log(
		'ApiControlField',
		field,
		formValues,
		handleNamedContentFieldChange,
	);

	const handleUpdateSections = (sections: Section[]) => {
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					...formValues[field.name]?.options,
					sections,
				},
			},
			field.name,
		);
	};
	const handleUpdateVariables = (variables: VariableType) => {
		handleNamedContentFieldChange(
			{
				value: formValues[field.name]?.value,
				options: {
					...formValues[field.name]?.options,
					globalVariables: variables,
				},
			},
			field.name,
		);
	};
	return (
		<div>
			<div>
				<ApiControlVariables
					onVariableChange={handleUpdateVariables}
					variables={formValues[field.name]?.options?.globalVariables || { '': '' }}
				/>
				<ApiControlSections
					onUpdateSections={handleUpdateSections}
					fieldSections={formValues[field.name]?.options?.sections || []}
					globalVariables={
						formValues[field.name]?.options?.globalVariables || { '': '' }
					}
				/>
			</div>
		</div>
	);
};

export default ApiControlField;
