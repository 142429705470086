import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
	getApiControlById,
	updateApiControl,
} from '../../api/apiRequests/apiControls';
import { useAppDispatch } from '../../hooks/redux';
import useEnqueuedSnackbar from '../../hooks/useEnqueuedSnackbar';
import { CONTENT_ENTITIES } from '../../lib/helpers/consts';
import { IApiControl } from '../../lib/interfaces/ApiControl';
import {
	setRecent,
	RecentEntities,
} from '../../redux/reducers/ui/recentOpenReducer';
import SingleContentEntity from '../Smart/SingleContentEntity';
import { apiControlFields } from './ApiControlsFormFields';

const ApiControlEdit = () => {
	const { id = '' } = useParams();
	const { useSuccessSnack } = useEnqueuedSnackbar();
	const dispatch = useAppDispatch();

	const singleApiControl = useQuery<IApiControl, Error>(
		[`ApiControl-${id}`],
		() => getApiControlById(Number(id)),
	);
	const { data, isLoading, error, refetch } = singleApiControl;

	const updateApiControlMutation = useMutation<IApiControl, Error, IApiControl>(
		(formValues: IApiControl) => updateApiControl(formValues, data?.id),
	);
	const {
		isLoading: isLoadingUpdate,
		isSuccess: isSuccessUpdate,
		error: errorUpdate,
	} = updateApiControlMutation;

	useEffect(() => {
		if (isSuccessUpdate) {
			useSuccessSnack('Api control has been updated successfully!');
			refetch();
		}
	}, [isSuccessUpdate]);

	useEffect(() => {
		dispatch(
			setRecent({
				entity: RecentEntities.API_CONTROL,
				link: `edit-api-control/${id}`,
			}),
		);
	}, [data]);

	const onSubmit = (formValues: any) => {
		if (data) {
			updateApiControlMutation.mutate(formValues);
		}
	};

	return data ? (
		<SingleContentEntity
			isEditMode
			onSubmit={onSubmit}
			isLoading={isLoading || isLoadingUpdate}
			initialValues={data}
			error={error || errorUpdate}
			saveButtonName="Edit"
			metaFields={apiControlFields}
			entityName="API Control"
			contentEntity={CONTENT_ENTITIES.API_CONTROL}
		/>
	) : null;
};

export default ApiControlEdit;
