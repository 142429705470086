import * as React from 'react';
import { CircularProgress, TablePagination, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Moment from 'react-moment';

import {
	IPagination,
	TPaginationSettings,
} from '../../lib/interfaces/Pagination';
import { User } from '../../lib/interfaces/User';
import { getPaginatedUsers } from '../../api/apiRequests/user';

const UsersTable = () => {
	const [pagination, setPagination] = React.useState<
		TPaginationSettings | undefined
	>({ page: 1, limit: 10 });
	const paginationKey = pagination
		? `${pagination.page}-${pagination.limit}`
		: '';
	const { data } = useQuery<IPagination<User>, Error>(
		[['users', paginationKey].join('_')],
		() => getPaginatedUsers({ pagination }),
	);
	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		page: number,
	) => {
		if (data) {
			setPagination({
				page: page + 1,
				limit: data.meta.itemsPerPage,
			});
		}
	};
	const users = data?.items.map((user) => ({
		...user,
		name: `${user.firstName} ${user.lastName}`,
		isActive: user.isActive ? 'yes' : 'no',
		roles: user.role.join(', '),
	}));
	return data && users ? (
		<>
			<Typography variant="h6">Users [{data.meta.totalItems}]</Typography>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell>Id</TableCell>
							<TableCell align="left">Name</TableCell>
							<TableCell align="left">Email</TableCell>
							<TableCell align="left">Roles</TableCell>
							<TableCell align="left">isActive</TableCell>
							<TableCell align="left">Created</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.map((row) => (
							<TableRow
								key={row.id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{row.id}
								</TableCell>
								<TableCell align="left">{row.name}</TableCell>
								<TableCell align="left">{row.email}</TableCell>
								<TableCell align="left">{row.roles}</TableCell>
								<TableCell align="left">{row.isActive}</TableCell>
								<TableCell align="left">
									<Moment format="DD.MM.YYYY HH:MM">{row.createdAt}</Moment>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[5]}
					component="div"
					count={data.meta.totalItems}
					rowsPerPage={data.meta.itemsPerPage}
					page={data.meta.currentPage - 1}
					onPageChange={handleChangePage}
				/>
			</TableContainer>
		</>
	) : (
		<CircularProgress />
	);
};

export default UsersTable;
